@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  @include Lnavyleft;
  width: 100%;
  background-color: $main-yellow-full;
  height: 2.5rem;
  text-transform: lowercase;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.mobileContainer {
  @include XSnavyleft;
  height: 1.25rem;
  padding-left: 0.5rem;
}
