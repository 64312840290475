@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  @include XSblueleft;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.icon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}
