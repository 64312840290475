@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.cardContainer {
  background-color: $main-greenish-lighter;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  padding-top: 0.75rem;
  padding-left: 1.312rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $contrast-grey-medium;
}

.contactCardContainer {
  background-color: transparent;
}

.cardInformationContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  width: calc(100% - 1.75rem);
}

.report {
  @include XSgrey_mediumleft;
  margin-top: 0.25rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  margin-bottom: 0.5rem;
}

.lastCRContainer {
  border: 2px solid $main-blue-full;
  border-radius: 2.375rem;
  width: 6.375rem;
  height: 1.5rem;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.8125rem;
}

.date {
  @include XSgrey_mediumleft;
  margin-bottom: 0.5rem;
}

.lateBadge {
  background-color: $system-warning;
  @include XSwhitefullcenter;
  width: 4.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2.375rem;
  height: 1.5rem;
}

.todoBadge {
  @include XSbluecenter;
  background-color: $contrast-grey-light;
  width: 3.188rem;
  border-radius: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
}
