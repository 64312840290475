@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.menuIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

.openButton {
  outline: none;
  background-color: $contrast-grey-light;
  border-radius: 8px;
  circle {
    fill: $main-navy-full;
  }
}

.triggerButton {
  padding: 0.5rem;
  min-width: 0rem;
  width: 2.5rem;
  svg {
    min-width: 1.5rem;
  }
}

.modalContent {
  margin-top: -2.75rem;
  margin-left: 0.25rem;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
}

.globalModalContainer {
  margin-left: -1rem;
  margin-right: -1rem;
  border-bottom: solid 1px $contrast-grey-light;
}

.mobileButton {
  margin-top: -1.5rem;
  margin-bottom: -0.5rem;
  margin-right: -0.5rem;
}
