@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.comMatrixCell {
  height: 3.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.greenishBackground {
  background-color: $main-greenish-lighter;
}

.comMatrixCellS {
  @include Snavyleft;
  padding-top: 0.5rem;
}

.comMatrixCellXS {
  padding-top: 0.688rem;
  @include XSnavyleft;
}

.comMatrixName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
}

.weekContent {
  padding-right: 0.812rem;
  padding-left: 0.812rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.subTitle {
  @include Mnavyleft;
  padding-bottom: 0.625rem;
}

.addContactLink {
  margin-top: 1rem;
}

.addContactIcon {
  height: 1rem;
  width: 1rem;
}

.progressionCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $contrast-grey-lighter;
  @include XSbluecenter;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.greenCheck {
  background-color: $system-sucess;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 100%;
}

.statusCell {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  align-items: center;
}

.statusLateCell {
  background-color: $system-warning;
  @include XSwhitefullcenter;
  justify-content: center;
}

.statusToDoCell {
  background-color: rgba(204, 211, 225, 0.4);
  @include XSbluecenter;
}

.report {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1; /* optionally extend to make labels line up */
}

table {
  width: 100%;
}

.actionTable tr {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:not(:last-child) > div > div {
    padding-right: 0.5rem;
  }

  td:nth-child(2) {
    width: 100%; /* extend the cell as much as possible */
    max-width: 0; /* avoid resizing beyond table width */
  }
}

.candidateRow {
  background-color: $main-greenish-lighter;
}

.tableSpacer {
  height: 4rem;
}
