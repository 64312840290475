@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include XSgrey_mediumleft;
  height: 2.5rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.checkBox {
  svg {
    max-width: 1rem;
    max-height: 1rem;
  }
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.checkboxLabel {
  @include Sblueleft;
}

.validateButtonContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3.5rem;
}

.selectedPopupItem {
  > div {
    @include Snavyleft;
    background-color: $contrast-grey-light;
  }
}

.checkIcon {
  color: $main-navy-full;
}
