@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  position: relative;
}

.image {
  position: absolute;
  top: 2.5rem;
  // needed to fix image in which half circles are not really horizontal
  left: -3rem;
}

.title {
  @include Lnavyleft;
}

.mobileImage {
  height: 20rem;
  width: 20rem;
}
