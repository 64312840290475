@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15rem;
  border-radius: 8px;
  background-color: $main-beige-full;
  border: solid 1px $contrast-grey-medium;
  padding: 1.2rem;
  padding-bottom: 0rem;
  overflow: hidden;
}

.container:hover {
  border: solid 2px $main-blue-full;
  padding-left: calc(1.2rem - 1px);
  padding-right: calc(1.2rem - 1px);
  padding-top: calc(1.2rem - 1px);
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.descriptionContainer {
  padding-top: 1.2rem;
  margin-right: -1.2rem;
  margin-left: -1.2rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  height: 40%;
}

.title {
  @include Sleft;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  @include XSgrey_mediumleft;
  color: $contrast-grey-strong;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  white-space: pre-line;
}

.description {
  @include Mnavyleft;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.headerRight {
  margin-top: -0.75rem;
  margin-right: -0.75rem;
}

.topPart {
  height: 8.3rem;
}
