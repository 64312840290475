@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listRow {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mobileListRow {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.cdiColor {
  background-color: $main-yellow-full;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badgeIcon {
  position: relative;
  top: 1rem;
  right: 1.25rem;
  width: 0px;
}

.smallBadge {
  height: 3rem;
  width: 3rem;
}
