@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.textField {
  margin-top: 1rem;
}

.firstTextField {
  margin-top: 0.5rem;
}

.modalIcon {
  margin-left: 1.5rem;
}

.text {
  @include Snavyleft;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
