@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 0.845rem;
}
.contractedHeader {
  position: absolute;
  top: 0px;
  background-color: $main-beige-full;
  height: 2rem;
  width: 100%;
  padding: 0.25rem 0 0.25rem 0;
  display: flex;
  justify-content: center;
}
.contractedHeaderText {
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -ms-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  opacity: 1;
  @include Mnavycenter;
}
.isContractedHeaderText {
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -ms-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  opacity: 0;
}

.mobileHeader {
  margin-left: 1rem;
  margin-right: 1rem;
}

.subHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: $contrast-grey-medium;
  margin-top: 1rem;
}

.text {
  margin-top: 0.5rem;
  @include Snavyleft;
}

.appNameAndBrand {
  margin-top: 1rem;
}

.currentUnitAndBrand {
  @include Sblueleft;
  &:hover {
    cursor: pointer;
  }
}

.title {
  @include Lleft;
}

.mobileTitle {
  @include XLleft;
}

.commentAndSearch {
  display: flex;
  justify-content: space-between;
}

.searchBar {
  margin-top: -1.75rem;
}
