@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: $main-navy-full;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.mobileContainer {
  flex-direction: column;
}
