@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
}
.headerSection {
  padding-left: 2rem;
  padding-right: 1rem;
  background-color: $main-beige-full;
}
.weekSections {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow: auto;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 1rem;
}

.weekSections::-webkit-scrollbar {
  display: none;
}
