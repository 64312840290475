@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex: 1;
  width: 100%;
  border-radius: 8px 0px 0px 8px;
  background-color: $main-beige-full;
  overflow: hidden;
}

.mobileContainer {
  border-radius: 0;
  width: 100%;
}
