@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.button {
  color: $main-white-medium;
  padding: 0.5rem;
  border-width: 0rem;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 0rem;
}

.openButton {
  outline: none;
  background-color: $contrast-grey-lighter;

  svg {
    path {
      fill-opacity: unset;
    }
  }
}

.buttonIcon {
  color: $main-white-full;
}
