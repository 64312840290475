@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.input {
  display: none;
}

.selectedText {
  @include Snavycenter;
}

.tabItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid $contrast-grey-medium;
  }
}
