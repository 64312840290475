@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.alphabet {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.5rem;
  overflow: hidden;
}

.mobileAlphabet {
  width: 0.625rem;
}

.letter {
  @include XSblueleft;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.selected {
  background-color: $contrast-grey-light;
}

.letter:hover {
  background-color: $contrast-grey-light;
  cursor: pointer;
}

.heightLimiter {
  max-height: 39rem;
  height: 100%;
}

.mobileHeightLimiter {
  max-height: 25rem;
}

.container {
  display: flex;
  width: 1.5rem;
  height: 100%;
  align-items: center;
}

.mobileContainer {
  width: 0.625rem;
}

.mobileLetter {
  @include XXSnavyleft;
  height: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0rem;
  align-items: center;
}
