@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.headerText {
  @include Snavyleft;
  margin-bottom: 1rem;
}

.textArea {
  width: 100%;
}

.searchField {
  margin-bottom: 1rem;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.closedClaim {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1.75rem;
  height: 2.5rem;
}

.closedClaimText {
  @include Snavyleft;
}

.errorMessage {
  @include XSerrorleft;
}
