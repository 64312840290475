@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.tabMenuContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include Sbluecenter;
}

.horizontalSeparator {
  @extend %separator;
  margin-top: 0.75rem;
}

.yellowBar {
  background-color: $main-yellow-full;
  height: 2px;
  margin-top: 10px;
  position: 'fixed';
}
