@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.mobileContainer {
  gap: 0.5rem;
}

.label {
  margin-top: 0.5rem;
}

.mobileLabel {
  font-size: 12px;
  line-height: 1.33;
  width: fit-content;
  text-align: center;
}

.item {
  padding-top: 1rem;

  &:hover {
    padding-top: calc(1rem - 1px) !important;
  }
}

.mobileItem {
  min-width: 0;
  height: 7.5rem;
}
