@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  margin-bottom: 1rem;
}

.perimeterSelectionRow {
  display: flex;
  gap: 1rem;
}

.companySelection {
  margin-top: 1rem;
}

.errorMessage {
  @include XSerrorleft;
  margin-top: 1rem;
  margin-bottom: -2rem;
}
