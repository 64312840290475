@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

/* min-width of card */
$mw: 15rem;

.cardRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($mw, 1fr));
  grid-gap: 1em;
  margin: 1em 0 1em;
}

.mobileCardRow {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
