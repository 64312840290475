@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  @extend %flex-center;
  width: 100%;
  height: 100%;

  background-color: $main-navy-full;
}

.modal {
  border: none;
  border-radius: 0.5rem;
  width: 20rem;

  background-color: $main-white-full;

  flex-direction: column;
  position: relative;
  padding: 1.5rem;
}

.title {
  @include Lleft;
}

.description {
  @include Sleft;
  margin-top: 0.5rem;
  margin-bottom: 3.5rem;
}

.logo {
  width: 9.5rem;
  height: 9.5rem;
  object-fit: contain;
  margin-bottom: 2rem;
}

.buttonContainer {
  background-color: $main-white-full;
  border-radius: 0.25rem;
}

.button {
  background-color: $main-white-full;
  width: 100%;
}
