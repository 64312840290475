@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.TTInformations {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.TTInformationsText {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
}

.TTName {
  @include Snavyleft;
  margin-bottom: 0.25rem;
}

.serviceName {
  @include XSgrey_mediumleft;
}
