@import '@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include XLnavyleft;
  margin-bottom: 1.5rem;
}

.menuItem {
  @include Sblueleft;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  svg {
    width: 3rem;
    height: 3rem;

    path {
      fill: currentColor;
    }
  }
}

.menuItem:hover:not(:active),
.menuItem:focus-visible {
  background-color: $contrast-grey-lighter;
  cursor: pointer;
}

.menuItem:active {
  background-color: $contrast-grey-light;
}

.spacer {
  width: 0.5rem;
}

.version {
  padding-top: 1rem;
  @include XSgrey_mediumleft;
}

.buttonMargin {
  margin-top: 1rem;
}

.logoutButton {
  width: 100%;
  height: 2.5rem;
}

.subText {
  @include XSgrey_mediumleft;
  @extend %one-line-text;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1rem;
}
.text {
  margin-bottom: 0.25rem;
}

.letterBadgeContainer {
  margin-bottom: 0.5rem;
}
