@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: 2rem;
  padding-right: 1rem;
}
