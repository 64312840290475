@import '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.headerSection {
  padding-left: 2rem;
  padding-right: 1rem;
  background-color: $main-beige-full;
}

.contactBook {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.contactList {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.contactBookInnerContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 0rem 2rem;
  background-color: $main-white-full;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.contactList::-webkit-scrollbar {
  display: none;
}

.alphabetPickerContainer {
  margin-left: 1.5rem;
}

.mobileAlphabetPickerContainer {
  margin-left: 0.438rem;
}

.mobileBook {
  margin-top: 1rem;
}

.mobileContainer {
  background-color: $main-white-full;
  padding-left: 1rem;
  padding-right: 0.438rem;
}
